export const HERO_LANG = {
  pt: {
    heroTitle: "Olá, eu sou Dinobergue Viana",
    heroRole: "Programador",
  },
  en: {
    heroTitle: "Hi, my name is Dinobergue Viana",
    heroRole: "Programmer",
  },
};
