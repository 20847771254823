export const EXPERIENCE_LANG = {
  pt: [
    {
      title: "Enjoei",
      role: "Desenvolvedor Frontend",
      period: "Mai 2024",
      jobSituation: "current",
      stacksText: "Trabalho aqui atualmente",
      stacks: null,
    },
    {
      title: "Educacros",
      role: "Desenvolvedor Frontend",
      period: "Set 2022 - Abr 2024",
      jobSituation: "past",
      stacksText: "Trabalhei com: ",
      stacks: ["Vue.js", ", Vuex", ", Bootstrap Vue", ", Vuexy"],
    },
    {
      title: "Warren Investimentos",
      role: "Desenvolvedor Frontend",
      period: "Jan 2022 - Ago 2022",
      jobSituation: "past",
      stacksText: "Trabalhei com: ",
      stacks: ["Vue.js", ", Vuex", ", Typescript"],
    },
    {
      title: "BoxTi",
      role: "Desenvolvedor",
      period: "Mar 2021 - Dez 2021",
      jobSituation: "past",
      stacksText: "Trabalhei com: ",
      stacks: [
        "HTML",
        ", CSS",
        ", Javascript",
        ", Vue.js",
        ", PHP",
        ", Node.js",
        ", MySQL",
      ],
    },
  ],
  en: [
    {
      title: "Enjoei",
      role: "Frontend Developer",
      period: "May 2024",
      jobSituation: "current",
      stacksText: "I currently work here",
      stacks: [],
    },
    {
      title: "Educacros",
      role: "Frontend Developer",
      period: "Sep 2022 - Apr 2024",
      jobSituation: "past",
      stacksText: "I worked with: ",
      stacks: [" Vue.js", ", Vuex", ", Bootstrap Vue", ", Vuexy"],
    },
    {
      title: "Warren Investimentos",
      role: "Frontend Developer",
      period: "Jan 2022 - Aug 2022",
      jobSituation: "past",
      stacksText: "I worked with: ",
      stacks: [" Vue.js", ", Vuex", ", Typescript"],
    },
    {
      title: "BoxTi",
      role: "Developer",
      period: "Mar 2021 - Dec 2021",
      jobSituation: "past",
      stacksText: "I worked with: ",
      stacks: [
        " HTML",
        ", CSS",
        ", Javascript",
        ", Vue.js",
        ", PHP",
        ", Node.js",
        ", MySQL",
      ],
    },
  ],
};
